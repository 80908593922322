import { computed, Ref } from 'vue'
import { useProjectsQuery } from './queries/useProjectsQuery'
import { useParams } from './utils/useParams'

export function useProject(projectId: Ref<string>) {
   const { customerId } = useParams()
   const { data: projects } = useProjectsQuery(customerId)

   return computed(() => projects.value?.find(m => m.id == projectId.value))
}
